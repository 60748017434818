interface StepProps {
  title: string;
  children: React.ReactNode;
}

export const Step = ({ title, children }: StepProps) => {
  return (
    <div className="flex w-full flex-col items-center gap-8 p-6">
      <h2 className="mb-4 text-center text-2xl font-bold">{title}</h2>
      {children}
    </div>
  );
};

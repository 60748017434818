import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { Input } from '@components/Input';
import { SelectField } from '@components/Select/SelectField.tsx';
import { Spinner } from '@components/Spinner';

import { selectProfile } from '@features/profile/profile';
import { MoveStatus } from '@features/profile/profile.types.ts';

import { ReactComponent as ClockAndStars } from '@images/clock-and-stars.svg';
import { ReactComponent as Handshake } from '@images/handshake.svg';
import { ReactComponent as Home } from '@images/home-v1.svg';
import { ChevronLeft, Tick } from '@images/icons';
import { ReactComponent as MovemntLogo } from '@images/movemnt-logo.svg';
import { ReactComponent as PoweredByMovemnt } from '@images/powered-by-movemnt.svg';
import { ReactComponent as Scales } from '@images/scales.svg';
import { ReactComponent as TrustpilotStarsGrey } from '@images/trustpilot-stars-grey.svg';
import { ReactComponent as TrustpilotStars } from '@images/trustpilot-stars.svg';

import { Instruction } from '@pages/services/Conveyancing/Instruction.tsx';
import { toggleSwitch } from '@pages/services/Conveyancing/toggleSwitch.tsx';

import customAxios from '@utils/customAxios.tsx';

export const postcodeRegExp =
  /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;

export const ServicesConveyancingPage = () => {
    const [sessionId, setSessionId] = useState('');
    const [quoteIds, setQuoteIds] = useState([] as string[]);
    const [quotes, setQuotes] = useState([] as HomeQuote[]);
    const [minQuotePrice, setMinQuotePrice] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [generateQuoteError, setGenerateQuoteError] = useState('');

    const profile = useSelector(selectProfile);
    const address = profile?.value?.properties?.[0];
    const moveType = profile?.value?.properties?.[0]?.moveType;

    const quoteTypeEnum = z.enum(['Sale', 'Purchase', 'Both'], { errorMap: () => ({ message: 'Choose quote type' }) });
    const tenureTypeEnum = z.enum(['Unknown', 'Leasehold', 'Freehold'], { errorMap: () => ({ message: 'Choose tenure type' }) });
    type Conveyance = {
      quoteType: z.infer<typeof quoteTypeEnum>,
      purchaseDetails: {
        address: Address,
        tenureType: z.infer<typeof tenureTypeEnum>,
        numberOfOwners: string;
        plannedUse: string;
        price: number;
        isRightToBuy: boolean;
        giftedEquity: boolean;
        giftedDeposit: boolean;
        numberOfGiftedDeposits: string;
        helpToBuyIsa: boolean;
        numberOfHelpToBuyIsas: string;
        soleBorrowerJointProprietor: boolean;
        jointBorrowerSoleProprietor: boolean;
        armedForcesHelpToBuy: boolean;
        isLimitedCompany: boolean;
        isUsingMortgage: boolean;
        haveMortgageLender: string;
        isHMO: boolean;
        helpToBuyEquityLoan: boolean;
        sharedOwnership: boolean;
        auction: boolean;
        firstTimeBuyer: boolean;
        newBuild: boolean;
      } | undefined,
      saleDetails: {
        address: Address,
        tenureType: z.infer<typeof tenureTypeEnum>,
        numberOfOwners: string;
        price: number;
        isSaleAtUndervalue: boolean;
        isLimitedCompany: boolean;
        isUsingMortgage: boolean;
        nextLender: string;
        isHMO: boolean;
        helpToBuyEquityLoan: boolean;
        sharedOwnership: boolean;
        auction: boolean;
      } | undefined,
    }
    type ConveyanceRequest = Omit<Conveyance, 'quoteType'> & {
      quoteType: 'SALE' | 'PURCHASE' | 'SALE_AND_PURCHASE'
    }

    function getSchema(quoteType: string) {
      return z.object({
        quoteType: quoteTypeEnum,
        purchaseDetails: quoteType === 'Sale'
          ? z.object({}).optional()
          : z.object({
            address: z.object({
              line1: z.string().min(1, 'Address line 1 required'),
              line2: z.string().optional(),
              city: z.string().min(1, 'City is required'),
              country: z.string().optional(),
              postcode: z
                .string()
                .min(1, 'Moving from postcode is required')
                .regex(postcodeRegExp, 'Invalid postcode'),
            }),
            tenureType: tenureTypeEnum,
            numberOfOwners: z.string().min(1, 'Choose number of owners')
              .transform(s => Number(s))
              .refine((val) => !isNaN(val), { message: 'Must be a number' }),
            price: z.string().min(5)
              .transform(s => Number(s.replace(/[£,]/g, '')))
              .refine((val) => !isNaN(val), { message: 'Must be a number' }),
            isRightToBuy: z.boolean(),
            isLimitedCompany: z.boolean(),
            isUsingMortgage: z.boolean(),
            giftedEquity: z.boolean(),
            giftedDeposit: z.boolean(),
            numberOfGiftedDeposits: z.string().transform(s => Number(s)),
            helpToBuyIsa: z.boolean(),
            numberOfHelpToBuyIsas: z.string().transform(s => Number(s)),
            soleBorrowerJointProprietor: z.boolean(),
            jointBorrowerSoleProprietor: z.boolean(),
            armedForcesHelpToBuy: z.boolean(),
            haveMortgageLender: z.string().optional().transform(s => {
              return s === 'Yes';
            }),
            plannedUse: z.enum(['Main residence', 'Buy to let', 'Additional residence', 'Holiday let', 'Unknown'])
              .transform(s => s === 'Main residence'
                ? 1 : s === 'Additional residence'
                  ? 2 : s === 'Buy to let'
                    ? 3 : s === 'Holiday let'
                      ? 4 : 0),
            isHMO: z.boolean(),
            helpToBuyEquityLoan: z.boolean(),
            sharedOwnership: z.boolean(),
            auction: z.boolean(),
            firstTimeBuyer: z.boolean(),
            newBuild: z.boolean(),
          }),
        saleDetails: quoteType === 'Purchase'
          ? z.object({}).optional()
          : z.object({
            address: z.object({
              line1: z.string().min(1, 'Address line 1 required'),
              line2: z.string().optional(),
              city: z.string().min(1, 'City is required'),
              country: z.string().optional(),
              postcode: z
                .string()
                .min(1, 'Moving from postcode is required')
                .regex(postcodeRegExp, 'Invalid postcode'),
            }),
            tenureType: tenureTypeEnum,
            numberOfOwners: z.string().min(1, 'Choose number of owners')
              .transform(s => Number(s))
              .refine((val) => !isNaN(val), { message: 'Must be a number' }),
            price: z.string().min(5)
              .transform(s => Number(s.replace(/[£,]/g, '')))
              .refine((val) => !isNaN(val), { message: 'Must be a number' }),
            isSaleAtUndervalue: z.boolean(),
            isLimitedCompany: z.boolean(),
            isUsingMortgage: z.boolean(),
            nextLender: z.string().refine(val => {
              return val === 'Unknown' || val === 'New' || val === 'Existing';
            }),
            isHMO: z.boolean(),
            helpToBuyEquityLoan: z.boolean(),
            sharedOwnership: z.boolean(),
            auction: z.boolean(),
          }),
      });
    }

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid },
    } = useForm<Conveyance>({
      mode: 'onBlur',
      resolver: async (values, context, options) => {
        const createResolver = zodResolver(getSchema(values.quoteType));
        const promise = createResolver(values, context, options);
        // promise.then(res => console.log(res));
        return promise;
      },
      defaultValues: {
        quoteType: undefined,
        purchaseDetails: moveType === MoveStatus.BUYER ? {
          address: {
            line1: address?.addressLine1,
            line2: address?.addressLine2,
            city: address?.city,
            postcode: address?.postcode,
          },
          isHMO: false,
          giftedDeposit: false,
          numberOfGiftedDeposits: '1',
          helpToBuyIsa: false,
          numberOfHelpToBuyIsas: '1',
          helpToBuyEquityLoan: false,
          sharedOwnership: false,
          firstTimeBuyer: false,
          newBuild: false,
          auction: false,
          isLimitedCompany: false,
          isUsingMortgage: false,
          isRightToBuy: false,
          numberOfOwners: undefined,
          tenureType: undefined,
        } : undefined,
        saleDetails: moveType === MoveStatus.SELLER ? {
          address: {
            line1: address?.addressLine1,
            line2: address?.addressLine2,
            city: address?.city,
            postcode: address?.postcode,
          },
          isHMO: false,
          helpToBuyEquityLoan: false,
          sharedOwnership: false,
          auction: false,
          isLimitedCompany: false,
          isUsingMortgage: false,
          isSaleAtUndervalue: false,
          numberOfOwners: undefined,
          tenureType: undefined,
        } : undefined,
      },
    });

    const onSubmit = async (data: Conveyance) => {
      setGenerateQuoteError('');
      setIsSubmitting(true);
      const quoteType: 'Sale' | 'Purchase' | 'Both' = data.quoteType;
      const transformedQuote = quoteType === 'Both' ? 'SALE_AND_PURCHASE' : quoteType.toUpperCase();
      const body = {
        ...data,
        quoteType: transformedQuote as 'SALE' | 'PURCHASE' | 'SALE_AND_PURCHASE',
        purchaseDetails: (typeof data.purchaseDetails === 'undefined'
          || Object.keys(data.purchaseDetails).length === 0
          || quoteType === 'Sale')
          ? undefined : data.purchaseDetails,
        saleDetails: (typeof data.saleDetails === 'undefined'
          || Object.keys(data.saleDetails).length === 0
          || quoteType === 'Purchase')
          ? undefined : data.saleDetails,
      } satisfies ConveyanceRequest;
      try {
        await generateQuotes(body);
      } catch (e) {
        Sentry.captureException(e);
        setIsSubmitting(false);
        setGenerateQuoteError('Failed to fetch quotes. Open chat and speak with us directly.');
        return;
      }
      setIsSubmitting(false);
      const nextStep = currentStep.index + 1;
      setCurrentStep(steps[quoteType.toLowerCase()].steps[nextStep]);
    };

    const quoteType = watch('quoteType');
    const purchaseDetails = watch('purchaseDetails');
    const saleDetails = watch('saleDetails');
    const isPurchaseUsingMortgage = watch('purchaseDetails.isUsingMortgage');
    const isSaleUsingMortgage = watch('saleDetails.isUsingMortgage');
    const isGiftedDeposit = watch('purchaseDetails.giftedDeposit');
    const isHelpToBuyIsa = watch('purchaseDetails.helpToBuyIsa');

    const steps: { [key: string]: Flow } = useMemo(() => ({
      sale: {
        steps: [
          { index: 0, name: 'QUOTE_TYPE', nextButtonText: 'Next: property' },
          { index: 1, name: 'SALE_PROPERTY_ADDRESS', nextButtonText: 'Next: finance' },
          { index: 2, name: 'SALE_PROPERTY_PRICING', nextButtonText: 'Next: quotes' },
          { index: 3, name: 'QUOTES', nextButtonText: '' },
          { index: 4, name: 'INSTRUCT', nextButtonText: '' },
        ],
        totalSteps: 3,
      },
      purchase: {
        steps: [
          { index: 0, name: 'QUOTE_TYPE', nextButtonText: 'Next: property' },
          { index: 1, name: 'PURCHASE_PROPERTY_ADDRESS', nextButtonText: 'Next: finance' },
          { index: 2, name: 'PURCHASE_PROPERTY_PRICING', nextButtonText: 'Next: quotes' },
          { index: 3, name: 'QUOTES', nextButtonText: '' },
          { index: 4, name: 'INSTRUCT', nextButtonText: '' },
        ],
        totalSteps: 3,
      },
      both: {
        steps: [
          { index: 0, name: 'QUOTE_TYPE', nextButtonText: 'Next: property' },
          { index: 1, name: 'PURCHASE_PROPERTY_ADDRESS', nextButtonText: 'Next: finance' },
          { index: 2, name: 'PURCHASE_PROPERTY_PRICING', nextButtonText: 'Next: property' },
          { index: 3, name: 'SALE_PROPERTY_ADDRESS', nextButtonText: 'Next: finance' },
          { index: 4, name: 'SALE_PROPERTY_PRICING', nextButtonText: 'Next: quotes' },
          { index: 5, name: 'QUOTES', nextButtonText: '' },
          { index: 6, name: 'INSTRUCT', nextButtonText: '' },
        ],
        totalSteps: 5,
      },
    }), []);

    const [selectedQuoteName, setSelectedQuoteName] = useState('');
    const [selectedSteps, setSelectedSteps] = useState(steps['purchase']);
    const [currentStep, setCurrentStep] = useState(selectedSteps.steps[0]);

    useEffect(() => {
      if (quoteType === 'Sale') {
        setSelectedSteps(steps['sale']);
      } else if (quoteType === 'Purchase') {
        setSelectedSteps(steps['purchase']);
      } else if (quoteType === 'Both') {
        setSelectedSteps(steps['both']);
      }
    }, [quoteType, steps]);

    const quoteTypeSelected = quoteType === 'Sale' || quoteType === 'Purchase' || quoteType === 'Both';
    const stepInputtingData = currentStep?.name !== 'QUOTES'
      && currentStep?.name !== 'INSTRUCT';

    useEffect(() => {
      const listener = (e: any) => {
        const currentStep = e.state.step ?? 0;
        const prevStep = sessionStorage.getItem('prev-step') ?? 0;
        if (Number(currentStep) < Number(prevStep)) {
          sessionStorage.setItem('prev-step', (Number(prevStep) - 1).toString());
          const backButton = document.querySelector('.back-button') as HTMLButtonElement;
          if (backButton) {
            backButton.click();
          }
        }
      };
      window.addEventListener('popstate', listener);
    }, []);


    function goToNextStep() {
      const nextStep = currentStep.index + 1;
      window.history.pushState({ step: nextStep }, 'unused', window.location.href);
      sessionStorage.setItem('prev-step', nextStep.toString());
      setCurrentStep(steps[quoteType.toLowerCase()].steps[nextStep]);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }

    const selectedQuote = quotes.find(it => it.name === selectedQuoteName);

    return (
      <div>
        <div
          className={(isSubmitting ? ' ' : 'hidden ') + 'fixed top-0 left-0 w-full h-screen bg-home z-[99] opacity-80'}>
          <div className={'absolute top-1/2 left-1/2 z-[100] text-primary'}>
            <Spinner colour={'black'} />
          </div>
        </div>
        <header
          className="mx-auto my-4 flex w-full max-w-3xl flex-col items-center justify-center gap-5 px-5 lg:max-w-[1256px]">
          <Scales />
          {(currentStep.name !== 'QUOTES' && currentStep.name !== 'INSTRUCT')
            ? <div className={'flex flex-col items-center gap-y-5'}>
              <h2 className="flex items-baseline gap-2 text-center text-3xl font-medium md:text-4xl">
                View your conveyancer quotes in less than 60 seconds
              </h2>
              <div className={'lg:hidden'}>
                <PoweredByMovemnt />
              </div>
            </div> : null}
          {currentStep.name === 'QUOTES'
            ? <div className="flex flex-col items-center gap-y-5">
              <h2 className="flex items-baseline gap-2 text-center text-3xl font-medium md:text-4xl">
                Conveyancing from just £{minQuotePrice.toFixed(2)}
              </h2>
              <div className={'flex flex-col items-center gap-y-5 lg:hidden'}>
                <div className={'text-lg'}>Why use a networked conveyancer?</div>
                <div className={'mx-8 flex flex-col gap-y-2'}>
                  <div><Tick className={'inline mr-2'} width={'20px'} /> <span>Track every step right here with real-time updates at every stage</span>
                  </div>
                  <div><Tick className={'inline mr-2'} width={'20px'} />
                    <span>Shave 60 days off the completion time</span>
                  </div>
                  <div><Tick className={'inline mr-2'} width={'20px'} /> <span>No move, no legal fees</span></div>
                </div>
                <PoweredByMovemnt />
              </div>
            </div> : null}
          {currentStep.name === 'INSTRUCT'
            ? <div className="flex flex-col items-center gap-y-5">
              {showLogo(selectedQuote)}
              {selectedQuote?.name}
              <div className={'flex flex-col gap-y-2 items-center lg:hidden'}>
                <div className={'text-lg font-medium text-center'}>
                  {selectedQuote?.name}
                </div>
                {getTrustpilot(selectedQuote)}
                <div className={'flex items-center'}><MovemntLogo /> <span
                  className={'ml-2'}>Networked conveyancer</span>
                </div>
                <div className={'text-center'}>
                  You’ve made an excellent choice! Here is your quote breakdown:
                </div>
              </div>
            </div> : null}
          {currentStep.name === 'QUOTE_TYPE' ?
            <div className={'flex flex-col gap-y-4 lg:hidden'}>
              <div className="flex flex-col gap-y-2 items-center">
                <Handshake />
                <div className="text-center text-md font-medium">Trusted Handpicked Conveyancers</div>
                <div className="text-center text-sm">Our network of handpicked, regulated conveyancing solicitors
                  ensures
                  your
                  transaction is in safe hands
                </div>
              </div>
              <div className="flex flex-col gap-y-2 items-center">
                <ClockAndStars />
                <div className="text-center text-md font-medium">Conveyancing packages designed for you</div>
                <div className="text-center text-sm">Get instant quotes and instruct in minutes</div>
              </div>
              <div className="flex flex-col gap-y-2 items-center">
                <Home />
                <div className="text-center text-md font-medium">Enjoy your new home sooner!</div>
                <div className="text-center text-sm">We shave an average of 60 days off the time it takes to complete
                </div>
              </div>
            </div>
            : null}
        </header>
        <main
          className={
            'box-border w-full px-5 pb-20 pt-4'
            + ((currentStep.name === 'QUOTES')
              ? ' bg-[#EB3754] lg:bg-[#EB3754]'
              : (currentStep.name === 'INSTRUCT') ? ' bg-white lg:bg-[#EB3754]' : ' bg-white lg:bg-secondary')
          }>
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="flex flex-col items-center w-full gap-y-7">

              <div className={'flex w-full md:w-2/5 lg:w-full gap-x-8 lg:max-w-[1256px] '
                + (currentStep.name === 'INSTRUCT' ? 'lg:w-3/4' : '')}>
                {
                  currentStep.index >= 1 ?
                    <div className="self-start">
                      {backButton((currentStep.name === 'QUOTES' || currentStep.name === 'INSTRUCT') ? 'text-white' : 'text-black')}
                      <div></div>
                    </div>
                    : null
                }
                <div className={''}></div>
              </div>

              <div className={'flex w-full gap-x-8 lg:max-w-[1256px]'}>
                <div
                  className={
                    'flex w-full justify-center p-4 lg:justify-start '
                    + ((currentStep.name !== 'INSTRUCT') ? 'lg:h-fit lg:w-2/3 lg:rounded-2xl ' : 'hidden ')
                    + (currentStep.name === 'QUOTES' ? 'lg:bg-home' : 'lg:bg-white')
                  }>

                  <section className={currentStep.name === 'QUOTE_TYPE' ? 'w-full md:w-[400px]' : 'hidden'}>
                    <div className={'flex flex-col items-start gap-y-8 py-4'}>
                      <div className={'text-2xl'}>What do you need help with?</div>
                      <div className={'w-[200px]'}>
                        <SelectField
                          styles="w-full"
                          options={[
                            { key: 'Looking at...', value: 'Looking at...', disabled: true },
                            { key: 'Selling', value: 'Sale' },
                            { key: 'Purchasing', value: 'Purchase' },
                            { key: 'Both', value: 'Both' },
                          ]}
                          id="quoteType"
                          label="I am"
                          required
                          key={quoteType}
                          error={errors.quoteType}
                          {...register('quoteType')}
                        />
                      </div>
                      <button
                        className={'next-button rounded-xl bg-primary px-8 py-3 text-xl text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer'}
                        type="button"
                        disabled={!quoteTypeSelected}
                        onClick={() => {
                          goToNextStep();
                        }}
                      >
                        Get started
                      </button>
                    </div>
                  </section>

                  <section
                    className={currentStep.name === 'PURCHASE_PROPERTY_ADDRESS' ? 'w-full md:w-[400px]' : 'hidden'}>
                    <div className="flex flex-col items-center w-full gap-y-4">
                      {stepXofY(currentStep, selectedSteps)}
                      <div className="text-lg font-medium self-start">Your Purchase Property details</div>
                      <div className="text-md self-start">We just need some information on your property so we can
                        give
                        you
                        accurate quotes
                      </div>
                      <Input
                        id="movingFrom.line1"
                        label="Address Line 1"
                        placeholder="Address line 1"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.address?.line1}
                        {...register('purchaseDetails.address.line1')}
                      />
                      <Input
                        id="purchaseDetails.address.line2"
                        label="Address Line 2"
                        placeholder="Address line 2"
                        error={errors.purchaseDetails?.address?.line2}
                        {...register('purchaseDetails.address.line2')}
                      />
                      <Input
                        id="purchaseDetails.address.city"
                        label="City"
                        placeholder="City"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.address?.city}
                        {...register('purchaseDetails.address.city')}
                      />
                      <Input
                        id="purchaseDetails.address.postcode"
                        label="Postcode"
                        placeholder="Postcode"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.address?.postcode}
                        {...register('purchaseDetails.address.postcode')}
                      />
                      <SelectField
                        options={[
                          { key: 'England', value: 'England' },
                          { key: 'Wales', value: 'Wales' },
                          { key: 'Ireland', value: 'Ireland' },
                          { key: 'Scotland', value: 'Scotland' },
                        ]}
                        styles={'w-full'}
                        id="purchaseDetails.address.country"
                        label="Country"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.address?.country}
                        {...register('purchaseDetails.address.country')}
                      />
                      <SelectField
                        styles="w-full"
                        options={[
                          { key: 'Leasehold', value: 'Leasehold' },
                          { key: 'Freehold', value: 'Freehold' },
                          { key: 'Unknown', value: 'Unknown' },
                        ]}
                        id="tenureType"
                        label="Tenure type"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.tenureType}
                        {...register('purchaseDetails.tenureType')} />
                      <SelectField
                        options={[
                          { key: '1', value: '1' },
                          { key: '2', value: '2' },
                          { key: '3', value: '3' },
                          { key: '4', value: '4' },
                        ]}
                        styles={'w-full'}
                        id="purchaseDetails.numberOfOwners"
                        label="Number of owners"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.numberOfOwners}
                        {...register('purchaseDetails.numberOfOwners')}
                      />
                    </div>
                  </section>

                  <section
                    className={currentStep.name === 'PURCHASE_PROPERTY_PRICING' ? 'w-full md:w-[400px]' : 'hidden'}>
                    {stepXofY(currentStep, selectedSteps)}
                    <div className={'mt-4'}></div>
                    <div className="text-lg font-medium self-start mb-4">Your Purchase Property finances</div>
                    <div className="text-md self-start mb-10">Now a few quick questions on how much the property is
                      worth
                      and
                      your
                      mortgage if relevant
                    </div>
                    <div className="flex flex-col items-center w-full gap-y-4">
                      <Input
                        id="purchaseDetails.price"
                        label="Property purchase price"
                        placeholder="Price"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.price}
                        {...register('purchaseDetails.price')}
                        onChange={(v: any) => {
                          v.target.value = '£' + Number(v.target.value.replace(/,/g, '').replace('£', '')).toLocaleString('en-GB');
                        }}
                        type="text"
                      />
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.isUsingMortgage') }, 'purchaseDetails.isUsingMortgage',
                          <span>Are you using a mortgage?</span>, false)
                        }
                      </div>
                      {
                        isPurchaseUsingMortgage ?
                          <SelectField
                            options={[
                              { key: 'Yes', value: 'Yes' },
                              { key: 'No', value: 'No' },
                            ]}
                            styles={'w-full'}
                            id="purchaseDetails.haveMortgageLender"
                            label="Do you have a mortgage lender yet?"
                            required={quoteType !== 'Sale' && isPurchaseUsingMortgage}
                            error={errors.purchaseDetails?.haveMortgageLender}
                            {...register('purchaseDetails.haveMortgageLender')}
                          />
                          : null
                      }
                      <SelectField
                        options={[
                          { key: 'Main residence', value: 'Main residence' },
                          { key: 'Additional residence', value: 'Additional residence' },
                          { key: 'Buy to let', value: 'Buy to let' },
                          { key: 'Holiday let', value: 'Holiday let' },
                          { key: 'Unknown', value: 'Unknown' },
                        ]}
                        styles={'w-full'}
                        id="purchaseDetails.plannedUse"
                        label="Planned use for property"
                        required={quoteType !== 'Sale'}
                        error={errors.purchaseDetails?.plannedUse}
                        {...register('purchaseDetails.plannedUse')}
                      />
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.isRightToBuy') }, 'purchaseDetails.isRightToBuy',
                          <span>Purchasing with Right to buy?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.giftedEquity') }, 'purchaseDetails.giftedEquity',
                          <span>Purchasing with Gifted Equity?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.giftedDeposit') }, 'purchaseDetails.giftedDeposit',
                          <span>Gifted deposit?</span>, false)
                        }
                      </div>
                      <div className={'self-start ' + (isGiftedDeposit ? '' : 'hidden')}>
                        <SelectField
                          id={'purchaseDetails.numberOfGiftedDeposits'}
                          {...register('purchaseDetails.numberOfGiftedDeposits')}
                          label={'Number of gifted deposits'}
                          styles={'w-full'}
                          options={[
                            { key: '1', value: '1' },
                            { key: '2', value: '2' },
                            { key: '3', value: '3' },
                            { key: '4', value: '4' },
                          ]}
                        ></SelectField>
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.helpToBuyIsa') }, 'purchaseDetails.helpToBuyIsa',
                          <span>Help to buy ISA?</span>, false)
                        }
                      </div>
                      <div className={'self-start ' + (isHelpToBuyIsa ? '' : 'hidden')}>
                        <SelectField
                          id={'purchaseDetails.numberOfHelpToBuyIsas'}
                          {...register('purchaseDetails.numberOfHelpToBuyIsas')}
                          label={'Number of help to buy ISAs'}
                          styles={'w-full'}
                          options={[
                            { key: '1', value: '1' },
                            { key: '2', value: '2' },
                            { key: '3', value: '3' },
                            { key: '4', value: '4' },
                          ]}
                        ></SelectField>
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.soleBorrowerJointProprietor') }, 'purchaseDetails.soleBorrowerJointProprietor',
                          <span>Sole Borrower Joint Proprietor?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.jointBorrowerSoleProprietor') }, 'purchaseDetails.jointBorrowerSoleProprietor',
                          <span>Joint Borrower Sole Proprietor?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.isLimitedCompany') }, 'purchaseDetails.isLimitedCompany',
                          <span>Is the property being sold in the name of a Limited Company?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.isHMO') }, 'purchaseDetails.isHMO',
                          <span>Is this a House of Multiple Occupancy (HMO)?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.helpToBuyEquityLoan') }, 'purchaseDetails.helpToBuyEquityLoan',
                          <span>Redemption of Help to Buy equity loan?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.sharedOwnership') }, 'purchaseDetails.sharedOwnership',
                          <span>Shared ownership?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.auction') }, 'purchaseDetails.auction',
                          <span>Buying at auction?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.firstTimeBuyer') }, 'purchaseDetails.firstTimeBuyer',
                          <span>First time buyer?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.newBuild') }, 'purchaseDetails.newBuild',
                          <span>New build?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('purchaseDetails.armedForcesHelpToBuy') }, 'purchaseDetails.armedForcesHelpToBuy',
                          <span>Armed forces help to buy?</span>, false)
                        }
                      </div>
                    </div>
                  </section>

                  <section
                    className={currentStep.name === 'SALE_PROPERTY_ADDRESS' ? 'w-full md:w-[400px]' : 'hidden'}>
                    <div className="flex flex-col items-center w-full gap-y-4">
                      {stepXofY(currentStep, selectedSteps)}
                      <div className="text-lg font-medium self-start">Your Sale Property details</div>
                      <div className="text-md self-start">We just need some information on your property so we can
                        give
                        you
                        accurate quotes
                      </div>
                      <Input
                        id="movingFrom.line1"
                        label="Address Line 1"
                        placeholder="Address line 1"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.address?.line1}
                        {...register('saleDetails.address.line1')}
                      />
                      <Input
                        id="saleDetails.address.line2"
                        label="Address Line 2"
                        placeholder="Address line 2"
                        error={errors.saleDetails?.address?.line2}
                        {...register('saleDetails.address.line2')}
                      />
                      <Input
                        id="saleDetails.address.city"
                        label="City"
                        placeholder="City"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.address?.city}
                        {...register('saleDetails.address.city')}
                      />
                      <Input
                        id="saleDetails.address.postcode"
                        label="Postcode"
                        placeholder="Postcode"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.address?.postcode}
                        {...register('saleDetails.address.postcode')}
                      />
                      <SelectField
                        options={[
                          { key: 'England', value: 'England' },
                          { key: 'Wales', value: 'Wales' },
                          { key: 'Ireland', value: 'Ireland' },
                          { key: 'Scotland', value: 'Scotland' },
                        ]}
                        styles={'w-full'}
                        id="saleDetails.address.country"
                        label="Country"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.address?.country}
                        {...register('saleDetails.address.country')}
                      />
                      <SelectField
                        styles="w-full"
                        options={[
                          { key: 'Leasehold', value: 'Leasehold' },
                          { key: 'Freehold', value: 'Freehold' },
                          { key: 'Unknown', value: 'Unknown' },
                        ]}
                        id="tenureType"
                        label="Tenure type"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.tenureType}
                        {...register('saleDetails.tenureType')} />
                      <SelectField
                        options={[
                          { key: '1', value: '1' },
                          { key: '2', value: '2' },
                          { key: '3', value: '3' },
                          { key: '4', value: '4' },
                        ]}
                        styles={'w-full'}
                        id="saleDetails.numberOfOwners"
                        label="Number of owners"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.numberOfOwners}
                        {...register('saleDetails.numberOfOwners')}
                      />
                    </div>
                  </section>

                  <section
                    className={currentStep.name === 'SALE_PROPERTY_PRICING' ? 'w-full md:w-[400px]' : 'hidden'}>
                    {stepXofY(currentStep, selectedSteps)}
                    <div className={'mt-4'}></div>
                    <div className="text-lg font-medium self-start mb-4">Your Sale Property finances</div>
                    <div className="text-md self-start mb-10">
                      Now a few quick questions on how much the property is worth and your mortgage if relevant
                    </div>
                    <div className="flex flex-col items-center w-full gap-y-4">
                      <Input
                        id="saleDetails.price"
                        label="Property sale price"
                        placeholder="250000"
                        required={quoteType !== 'Purchase'}
                        error={errors.saleDetails?.price}
                        {...register('saleDetails.price')}
                        onChange={(v: any) => {
                          v.target.value = '£' + Number(v.target.value.replace(/,/g, '').replace('£', '')).toLocaleString('en-GB');
                        }}
                        type="text"
                      />
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.isUsingMortgage') }, 'saleDetails.isUsingMortgage',
                          <span>Is there a mortgage left to pay off?</span>, false)
                        }
                      </div>
                      <div className={isSaleUsingMortgage ? 'w-full md:w-[400px]' : 'hidden'}>
                        <SelectField
                          options={[
                            { key: 'New', value: 'New' },
                            { key: 'Existing', value: 'Existing' },
                            { key: 'Unknown', value: 'Unknown' },
                          ]}
                          styles={'w-[97%] ml-[3%]'}
                          id="saleDetails.nextLender"
                          label="> My next mortgage lender is"
                          required={quoteType !== 'Purchase'}
                          error={errors.saleDetails?.nextLender}
                          {...register('saleDetails.nextLender')}
                        />
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.isSaleAtUndervalue') }, 'saleDetails.isSaleAtUndervalue',
                          <span>Is the sale at undervalue?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.isLimitedCompany') }, 'saleDetails.isLimitedCompany',
                          <span>Is the property being sold in the name of a Limited Company?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.isHMO') }, 'saleDetails.isHMO',
                          <span>Is this a House of Multiple Occupancy (HMO)?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.helpToBuyEquityLoan') }, 'saleDetails.helpToBuyEquityLoan',
                          <span>Redemption of Help to Buy equity loan?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.sharedOwnership') }, 'saleDetails.sharedOwnership',
                          <span>Shared ownership?</span>, false)
                        }
                      </div>
                      <div className={'self-start'}>
                        {toggleSwitch({ ...register('saleDetails.auction') }, 'saleDetails.auction',
                          <span>Selling at auction?</span>, false)
                        }
                      </div>
                    </div>
                  </section>

                  <section className={currentStep.name === 'QUOTES' ? 'w-[400px] lg:w-full' : 'hidden'}>
                    <div className="mx-auto box-border w-full max-w-6xl">
                      <div className="flex w-full flex-col items-center justify-center gap-10 lg:gap-4">
                        <div
                          className={'flex items-center w-full justify-around lg:justify-between'}>
                          <div className={'text-white'}>{quotes.length} quotes</div>
                          <div className={'w-1/2 lg:w-1/3 xl:w-1/4 flex items-center gap-x-2 text-white'}>
                            <div className={'text-white'}>Sort</div>
                            <div>by</div>
                            <SelectField
                              id={'sort-quotes-by'}
                              styles={'w-full'}
                              label={''}
                              options={[
                                { key: 'Price', value: 'Price' },
                                { key: 'Rating', value: 'Rating' },
                              ]}
                              onSelect={(value) => {
                                if (value === 'Price') {
                                  const byPrice = orderQuotesByPrice(quotes);
                                  setQuotes(byPrice);
                                } else {
                                  const byRating = orderQuotesByRating(quotes);
                                  setQuotes(byRating);
                                }
                              }}
                            >
                            </SelectField>
                          </div>
                        </div>

                        {/* mobile view of quotes */}
                        <div
                          className={'lg:hidden flex w-full flex-col items-center justify-center gap-10 md:gap-14'}>
                          {quotes.map((q, index) => (
                            <div
                              className="flex flex-col items-center gap-y-4 w-[80vw] md:w-[400px] bg-white rounded-xl p-8"
                              key={`quote-sm-${index}`}>
                              {showLogo(q)}
                              <span className={'text-lg font-medium'}>{q.name}</span>
                              {getTrustpilot(q)}
                              <div className="flex flex-col items-center gap-y-1">
                                <article>
                                  <details className="">
                                    <summary className="underline cursor-pointer mb-2">Show more</summary>
                                    {q.sale ? <div>
                                      <div className={'font-medium underline mb-2'}>Sale</div>
                                      {q.sale.fees.map(f => (
                                        <div className={'flex justify-between'}>
                                          <div className={'self-start'}>{f.name}:</div>
                                          <div className={'self-end'}>£{f.netValue}</div>
                                        </div>
                                      ))
                                      }
                                    </div> : null}
                                    {q.purchase ? <div>
                                      <div className={'font-medium underline mb-2 ' + (q.sale ? 'mt-2' : '')}>Purchase
                                      </div>
                                      {q.purchase.fees.map(f => (
                                        <div className={'flex justify-between'}>
                                          <div className={'self-start'}>{f.name}:</div>
                                          <div className={'self-end'}>£{f.netValue}</div>
                                        </div>
                                      ))
                                      }
                                    </div> : null}
                                    <div className={'flex justify-between mt-4'}><span
                                      className={'font-medium underline mb-2'}>VAT:</span>
                                      <span>£{q.totalVat.toFixed(2)}</span></div>
                                  </details>
                                </article>
                                <div className={'flex justify-between gap-x-2 items-end my-4'}>
                                  <div className={'font-medium text mb-[1px]'}>Total price:&nbsp;</div>
                                  <div
                                    className={'self-end text-[#EB3754] text-3xl'}>£{q.totalCost.toFixed(2)}</div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="next-button rounded-xl w-[80%] bg-primary px-8 py-3 text-md text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer"
                                onClick={() => {
                                  setSelectedQuoteName(q.name);
                                  setQuoteIds(q.ids);
                                  goToNextStep();
                                }}
                              >
                                Next step
                              </button>
                            </div>
                          ))}

                          <div className={'flex flex-col items-center gap-y-4'}>
                            <div className={'text-xl text-white font-medium'}>What to expect</div>
                            <div className={'text-white text-center'}>With a conveyancer or solicitor on your side,
                              you
                              can feel confident you won’t get any nasty surprises down the line.
                              Be prepared for the conveyancing process to take several weeks, depending on the
                              complexity
                              of the sale.
                            </div>
                          </div>
                        </div>

                        {/* desktop view of quotes */}
                        <div
                          className={'hidden lg:flex w-full flex-col items-center justify-center gap-10 md:gap-14'}>
                          {quotes.map((q, index) => (
                            <div className={'flex bg-white rounded-xl p-8 w-full max-w-[800px]'}>
                              <div
                                className="flex flex-col gap-y-4 w-2/3"
                                key={`quote-lg-${index}`}
                              >
                                {showLogo(q, false)}
                                <span className={'text-xl font-medium'}>{q.name}</span>
                                <div className="flex flex-col gap-y-1">
                                  {q.sale ? <div>
                                    <div className={'font-medium underline mb-2'}>Sale</div>
                                    {q.sale.fees.map(f => (
                                      <div className={'flex gap-x-2'}>
                                        <div className={'self-start'}>{f.name}:</div>
                                        <div className={'self-end'}>£{f.netValue}</div>
                                      </div>
                                    ))
                                    }
                                  </div> : null}
                                  {q.purchase ? <div>
                                    <div className={'font-medium underline mb-2 ' + (q.sale ? 'mt-2' : '')}>Purchase</div>
                                    {q.purchase.fees.map(f => (
                                      <div className={'flex gap-x-2'}>
                                        <div className={'self-start'}>{f.name}:</div>
                                        <div className={'self-end'}>£{f.netValue}</div>
                                      </div>
                                    ))
                                    }
                                    <div className={'mt-4'}><span
                                      className={'font-medium underline mb-2 mr-2'}>VAT:</span>
                                      <span>£{q.totalVat.toFixed(2)}</span></div>
                                  </div> : null}
                                </div>
                              </div>
                              <div className={'flex flex-col items-end justify-between w-1/3'}>
                                {getTrustpilot(q)}
                                <div>
                                  <div className={'flex flex-col justify-between gap-x-2 items-end my-4'}>
                                    <div className={'font-medium text mb-[1px]'}>Total price:&nbsp;</div>
                                    <div
                                      className={'self-end text-[#EB3754] text-3xl'}>£{q.totalCost.toFixed(2)}</div>
                                  </div>
                                  <button
                                    type="button"
                                    className="next-button rounded-xl w-[80%] bg-primary px-8 py-3 text-md text-white transition-all
                                    hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer min-w-40"
                                    onClick={() => {
                                      setSelectedQuoteName(q.name);
                                      setQuoteIds(q.ids);
                                      goToNextStep();
                                    }}
                                  >
                                    Next step
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                </div>

                {stepInputtingData
                  ? whyInstructSummary()
                  : (currentStep.name !== 'INSTRUCT') ?
                    <div
                      className={'flex flex-col mt-20 gap-y-4 w-[30%] px-4 hidden lg:flex lg:justify-start'}>
                      <PoweredByMovemnt className={'fill-amber-50 mb-4'} />
                      {whyInstructDetailed()}
                    </div> : null
                }
              </div>

              <button
                className={'next-button rounded-xl bg-primary px-8 py-3 text-xl text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer'}
                hidden={(currentStep.name === 'SALE_PROPERTY_PRICING' && (quoteType === 'Sale' || quoteType === 'Both'))
                  || (currentStep.name === 'PURCHASE_PROPERTY_PRICING' && quoteType === 'Purchase')
                  || currentStep.name === 'QUOTES'
                  || currentStep.name === 'INSTRUCT'
                  || currentStep.name === 'QUOTE_TYPE'}
                type="button"
                disabled={(currentStep.name === 'QUOTE_TYPE' && !quoteTypeSelected)
                  || (currentStep.name === 'PURCHASE_PROPERTY_ADDRESS' && !(
                      purchaseDetails?.address?.line1
                      && purchaseDetails?.address?.postcode
                      && purchaseDetails?.address?.city
                      && purchaseDetails?.address?.country
                      && purchaseDetails?.tenureType
                      && purchaseDetails?.numberOfOwners
                    )
                  )
                  || (currentStep.name === 'SALE_PROPERTY_ADDRESS' && !(
                      saleDetails?.address?.line1
                      && saleDetails?.address?.postcode
                      && saleDetails?.address?.city
                      && saleDetails?.address?.country
                      && saleDetails?.tenureType
                      && saleDetails?.numberOfOwners
                    )
                  )
                }
                onClick={() => {
                  goToNextStep();
                }}
              >
                {currentStep.nextButtonText}
              </button>

              <button
                className={
                  (currentStep.name === 'SALE_PROPERTY_PRICING' && (quoteType === 'Sale' || quoteType === 'Both')
                      ? ''
                      : (currentStep.name === 'PURCHASE_PROPERTY_PRICING' && quoteType === 'Purchase')
                        ? ''
                        : 'hidden'
                  )
                  + ' rounded-xl bg-primary px-8 py-3 text-xl text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer'}
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                Show me my quotes
              </button>

              {generateQuoteError !== ''
                ? <div className={'font-bold'}>{generateQuoteError}</div>
                : null}

            </div>
          </form>


          <div className="flex flex-col items-center w-full gap-y-7">
            <section className={currentStep.name === 'INSTRUCT' ? 'w-full md:w-[400px] lg:w-3/4' : 'hidden'}>
              {selectedQuote ?
                <Instruction
                  quoteIds={quoteIds}
                  sessionId={sessionId}
                  selectedQuote={selectedQuote}
                  correspondenceAddress={
                    quoteType === 'Purchase'
                      ? undefined
                      : saleDetails!.address
                  }
                  setIsSubmitting={setIsSubmitting}
                /> : <></>}
            </section>
          </div>

        </main>
      </div>
    )
      ;

    function whyInstructSummary() {
      return <div className={'flex flex-col gap-y-4 w-1/3 p-4 hidden lg:flex'}>
        <PoweredByMovemnt />
        <div className="flex gap-x-2 items-center bg-white rounded-2xl px-2 py-4">
          <Handshake width={'60px'} />
          <div className="text-center lg:text-start text-md font-medium">Trusted Handpicked Conveyancers</div>
        </div>
        <div className="flex gap-x-2 items-center bg-white rounded-2xl px-2 py-4">
          <ClockAndStars width={'60px'} />
          <div className="text-center ml-2 lg:text-start text-md font-medium">Conveyancing packages designed for you</div>
        </div>
        <div className="flex gap-x-2 items-center bg-white rounded-2xl px-2 py-4">
          <Home width={'60px'} />
          <div className="text-center lg:text-start text-md font-medium">Enjoy your new home sooner!</div>
        </div>
      </div>;
    }

    function getTrustpilot(q?: HomeQuote) {
      if (!q) return <></>;
      return <>
        {q.trustpilot.reviews > 0 ?
          <div className="flex flex-col items-center justify-center gap-x-4 gap-y-2">
            <div className={'flex'}>
              <div
                className={'absolute items-center stars-' + roundRating(q.trustpilot.rating)}>
                <TrustpilotStars width={'125px'} height={'25px'} />
              </div>
              <div
                className={'flex items-center stars-grey-' + roundRating(q.trustpilot.rating)}>
                <TrustpilotStarsGrey width={'125px'} height={'25px'} />
              </div>
            </div>
            <div className="font-medium">{q.trustpilot.reviews} reviews on Trustpilot</div>
          </div> : null}
      </>;
    }

    function backButton(textColor: string = 'text-black') {
      return <div className={'back-button flex mr-4 cursor-pointer ' + textColor}
                  hidden={currentStep.index === 0}
                  onClick={() => {
                    const prevStep = currentStep.index - 1;
                    setCurrentStep(steps[quoteType.toLowerCase()].steps[prevStep]);
                    window.scrollTo({ top: 20, behavior: 'smooth' });
                  }}
      >
        <ChevronLeft width={'20px'} className={textColor === 'text-black' ? '' : 'fill-amber-50'} />
        <span className={'ml-1'}>back</span>
      </div>;
    }

    async function generateQuotes(body: ConveyanceRequest) {
      const res = await customAxios('/api/home/conveyancing/quote', {
        method: 'POST',
        withCredentials: true,
        data: body,
      });
      const data = await res.data as { quotes: QuotesData | null, sessionId: string };
      if (data && typeof data === 'object' && 'quotes' in data && data.quotes != null) {
        if (data.quotes.quotes.length === 0) {
          setGenerateQuoteError('No quotes could be found');
          setQuotes([]);
        }
        const rawQuotes = data.quotes.quotes.map(quoteGroup => {
          const logo = quoteGroup.conveyancerBranch.company.logo;
          const trustpilot = quoteGroup.conveyancerBranch.company.trustpilot;
          return groupQuotes(quoteGroup, logo, trustpilot);
        });
        const highestRated = orderQuotesByRating(rawQuotes)?.slice(0, 5);
        const sorted = orderQuotesByPrice(highestRated);
        setSessionId(data.sessionId);
        setQuotes(sorted);
        setMinQuotePrice(sorted[0].totalCost);
      }
    }

    function groupQuotes(quoteGroup: BranchAndQuotes, logo: string, trustpilot: Trustpilot): HomeQuote {
      return quoteGroup.conveyancingQuotes.map(quote => {
        const name = quoteGroup.conveyancerBranch.name;
        const fees = quote.legalTransactionFees;
        const disbursements = quote.disbursements;
        const totalFees = fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0);
        const totalVat = fees.map(f => f.vatValue).reduce((tot, n) => tot += n, 0);
        const totalCost = totalFees + totalVat;
        const phone = quoteGroup.conveyancerBranch.phoneNumber;
        const email = quoteGroup.conveyancerBranch.email;
        const website = quoteGroup.conveyancerBranch.url;
        const quoteType = quote.quoteType;
        return {
          name,
          sale: quoteType === 1 ? {
            fees,
            disbursements,
            totalVat: totalVat,
            totalFees: totalFees,
            totalCost: totalCost,
          } : undefined,
          // @ts-ignore
          purchase: quoteType === 2 ? {
            fees,
            disbursements,
            totalVat: totalVat,
            totalFees: totalFees,
            totalCost: totalCost,
          } : undefined,
          totalVat: totalVat,
          totalFees: totalFees,
          totalCost: totalCost,
          logo,
          trustpilot,
          phone,
          email,
          website,
          ids: [quote.id],
        };
      }).reduce((acc, next) => {
        return {
          name: next.name,
          sale: acc.sale ? acc.sale : next.sale,
          purchase: acc.purchase ? acc.purchase : next.purchase,
          totalVat: acc.totalVat + next.totalVat,
          totalFees: acc.totalFees + next.totalFees,
          totalCost: acc.totalCost + next.totalCost,
          logo: next.logo,
          trustpilot: next.trustpilot,
          phone: next.phone,
          email: next.email,
          website: next.website,
          ids: acc.ids.concat(next.ids),
        };
      });
    }

    function orderQuotesByPrice(homeQuotes: HomeQuote[]) {
      return [...homeQuotes]?.sort((a, b) => {
        return a.totalCost - b.totalCost;
      });
    }

    function orderQuotesByRating(homeQuotes: HomeQuote[]) {
      return [...homeQuotes]?.sort((a, b) => {
        return b.trustpilot.rating - a.trustpilot.rating;
      });
    }

    function roundRating(rating: number) {
      if (rating >= 4.75) return '5';
      if (rating >= 4.25) return '4-5';
      if (rating >= 3.75) return '4';
      if (rating >= 3.25) return '3-5';
      if (rating >= 2.75) return '3';
      if (rating >= 2.25) return '2-5';
      if (rating >= 1.75) return '2';
      if (rating >= 1.25) return '1-5';
      return '1';
    }

  }
;

export function showLogo(q?: HomeQuote, center: boolean = true) {
  return <>
    {q?.logo ? <div className={'flex items-center' + (center ? ' justify-center' : '')}>
      <div className="w-20"><img alt="conveyancer-logo" src={q.logo} width="auto" /></div>
    </div> : null}
  </>;
}

export function whyInstructDetailed() {
  return <div>
    <div className={'flex flex-col gap-y-4 bg-white p-6 rounded-2xl'}>
      <div className={'font-medium'}>Why use a networked conveyancer?</div>
      <div className={'flex flex-col gap-y-2'}>
        <div className={'flex items-start'}>
          <Tick className={'inline mr-3 min-w-6'} width={'20px'} />
          <span>Track every step right here with real-time updates at every stage</span>
        </div>
        <div className={'flex items-start'}>
          <Tick className={'inline mr-3 min-w-6'} width={'20px'} />
          <span>Shave 60 days off the completion time</span>
        </div>
        <div className={'flex items-start'}>
          <Tick className={'inline mr-3 min-w-6'} width={'20px'} />
          <span>No move, no legal fees</span>
        </div>
      </div>
    </div>
    <div className={'flex flex-col gap-y-4 p-6 rounded-2xl text-white'}>
      <div className={'flex flex-col gap-y-4 '}>
        <div className={'text-xl font-medium'}>Progress tracker</div>
        <div>Add a networked conveyancer to begin tracking this step.</div>
      </div>
      <div className={'flex flex-col gap-y-4 '}>
        <div className={'text-xl font-medium'}>What to expect</div>
        <div>With a conveyancer or solicitor on your side, you can feel confident you won’t get any nasty surprises
          down the line.
          Be prepared for the conveyancing process to take several weeks.
        </div>
      </div>
    </div>
  </div>;
}

function stepXofY(currentStep: Step, selectedSteps: Flow) {
  return <div
    className="text-[#EB3754] inline self-start">Step {currentStep.index + 1}/{selectedSteps.totalSteps}
  </div>;
}


type CompanyAddress = {
  subBuildingNumber: null,
  subBuildingName: null,
  buildingNumber: null,
  buildingName: null,
  thoroughfare: null,
  line1: null,
  line2: null,
  line3: null,
  line4: null,
  locality: null,
  townOrCity: null,
  county: null,
  district: null,
  postcode: null,
  country: null,
  latitude: null,
  longitude: null
};
type Fee = {
  name: string | 'Fixed Legal Fees',
  netValue: number | 850,
  vatValue: number | 170,
  grossValue: number | 1020
};
type Quote = {
  disbursements: Fee[],
  legalTransactionFees: Fee[],
  estimatedStampDuty: 0,
  instructionDate: null,
  referralDate: null,
  id: string | 'f2cc02b0-428e-4042-af70-ad540c708786',
  quoteType: 1
};
type Trustpilot = {
  id: number | 59,
  rating: number | 0,
  reviews: number | 0,
  url: string | 'https://www.trustpilot.com/review/thorntonjones.co.uk'
};
type RegulatoryBody = {
  id: string | '815236',
  name: string | 'SRA',
  logo: string | 'https://assets.nonprod-txn.yourkeys.com/regulators/SRALogo.png',
  logoUrl: null,
  url: string | 'https://www.sra.org.uk/consumers/register/organisation/?sraNumber=815236'
};
type Company = {
  id: number | 0,
  name: string | 'Thornton Jones Solicitors Ltd',
  logo: string | 'https://assets.nonprod-txn.yourkeys.com/conveyancers/SRA-815236.png',
  address: CompanyAddress,
  phoneNumber: string | null,
  email: string | null,
  url: string | 'www.thorntonjones.co.uk',
  trustpilot: Trustpilot,
  regulatoryBody: RegulatoryBody,
  regulatoryId: number | 815236
};
type ConveyancerBranch = {
  company: Company,
  id: 9971,
  name: string | 'Thornton Jones Solicitors Ltd',
  logo: null,
  address: CompanyAddress,
  phoneNumber: string | '01924 290029',
  iovoxPhoneNumber: string | null,
  email: string | 'emily@thorntonslegal.com',
  enquiryEmail: string | null,
  url: string | 'www.thorntonjones.co.uk'
};
type BranchAndQuotes = {
  conveyancerBranch: ConveyancerBranch,
  conveyancingQuotes: Quote[],
  instructionDate: null,
  referralDate: null
};
type QuotesData = {
  quoteGroupId: string | '81cb1b16-b1fa-44e9-8114-589cb5280061',
  quotes: BranchAndQuotes[],
  quotesExpired: boolean,
  canInstruct: boolean,
  instructionDate: null
};
export type Address = {
  line1: string,
  line2: string,
  city: string,
  postcode: string,
  country: string,
};
export type QuoteLineItems = {
  totalFees: number;
  totalVat: number;
  totalCost: number;
  fees: Fee[];
  disbursements: Fee[];
}
export type HomeQuote = {
  ids: string[] | ['81cb1b16-b1fa-44e9-8114-589cb5280062'];
  sale?: QuoteLineItems,
  purchase?: QuoteLineItems,
  totalFees: number | 850.00;
  totalVat: number | 850.00;
  totalCost: number | 1700.00;
  trustpilot: Trustpilot;
  phone: string | '+44...';
  email: string | 'tom@home.com';
  name: string | 'Thornton Jones Solicitors Ltd';
  logo: string | 'https://assets.nonprod-txn.yourkeys.com/conveyancers/SRA-815236.png'
  website: string | 'https://assets.nonprod-txn.yourkeys.com/conveyancers/SRA-815236.png'
};

type StepName =
  | 'QUOTE_TYPE'
  | 'PURCHASE_PROPERTY_ADDRESS'
  | 'PURCHASE_PROPERTY_PRICING'
  | 'SALE_PROPERTY_ADDRESS'
  | 'SALE_PROPERTY_PRICING'
  | 'QUOTES'
  | 'INSTRUCT'

type Flow = { steps: Step[], totalSteps: number };
export type Step = { index: number, name: StepName, nextButtonText: string };

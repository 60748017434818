import { useSelector } from 'react-redux';

import { Map } from '@components/Map';
import { BuyerProtectionSignpost, SellerProtectionSignpost } from '@components/Signpost';

import { selectProfile } from '@features/profile/profile';
import { MoveStatus, Task } from '@features/profile/profile.types';

import { convertAddressToSingleLine } from '@utils/convertAddress';

import { Checklist } from '../../components/Checklist';

type Props = {
  moveStatus: string;
};

export const FindAProperty = ({ moveStatus }: Props) => {
  const { value: profile } = useSelector(selectProfile);

  const postcode = profile?.properties[0]?.postcode || '';
  const address = profile?.properties?.[0] ? convertAddressToSingleLine(profile.properties[0]) : '';

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="mx-auto flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <div className="flex w-full max-w-xl flex-col gap-5">
            {postcode && (
              <div className="block h-40 w-full overflow-hidden rounded-xl">
                <Map postcode={postcode} />
              </div>
            )}
            <div className="flex flex-col gap-1">
              <span className="font-heading text-2xl font-medium">£688,780</span>
              <h4 className="text-xl font-medium">{address}</h4>
            </div>
          </div>
        </div>
        {moveStatus !== MoveStatus.TENANCY && (
          <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
            <hr className="w-full border-stone-200 md:hidden" />
            {moveStatus === MoveStatus.BUYER && <BuyerProtectionSignpost />}
            {moveStatus === MoveStatus.SELLER && <SellerProtectionSignpost />}
          </div>
        )}
      </div>
    </>
  );
};

import { useFormContext } from 'react-hook-form';

import { Button } from '@components/Button';
import { TextField } from '@components/TextField';

import { NewUser } from '..';
import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
}

export const NameStep = ({ onNext, isActive }: StepProps) => {
  const { register, watch } = useFormContext<NewUser>();

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  if (!isActive) {
    return null;
  }

  const isButtonDisabled = !firstName || !lastName;

  return (
    <Step title="Firstly, what shall we call you?">
      <div className="flex gap-5">
        <TextField
          {...register('firstName', { required: 'First name is required' })}
          type="text"
          id="firstName"
          label="First name"
        />
        <TextField
          {...register('lastName', { required: 'Last name is required' })}
          type="text"
          id="lastName"
          label="Last name"
        />
      </div>
      <Button onClick={onNext} disabled={isButtonDisabled}>
        Next
      </Button>
    </Step>
  );
};

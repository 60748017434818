import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';

import { HomeLogo } from '@components/HomeLogo';

import { Routes } from '@constants/index';

import { MoveStatus } from '@features/profile/profile.types';

import { CurrentAddressStep } from './steps/CurrentAddress';
import { EmailStep } from './steps/Email';
import { MoveStatusStep } from './steps/MoveStatus';
import { MovingSoonStep } from './steps/MovingSoon';
import { NameStep } from './steps/Name';
import { NewAddressStep } from './steps/NewAddress';
import { StepSeven } from './steps/StepSeven';

export type NewUser = {
  sessionId: string;
  firstName: string;
  lastName: string;
  email: string;
  moveStatus: MoveStatus;
  currentAddress: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode: string;
  };
  newAddress?: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode: string;
  };
};

const propertySchema = z.object({
  addressLine1: z.string().min(1, 'Address line 1 required'),
  addressLine2: z.string().optional(),
  city: z.string().min(1, 'City is required'),
  postcode: z.string().min(1, 'Postcode is required'),
  moveType: z.string().min(1, 'Move type is required'),
});

const schema = z.object({
  sessionId: z.string().min(1, 'Session required'),
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email').min(1, 'Email is required'),
  properties: z.array(propertySchema).min(1, 'Must enter your current address'),
});

export const SignupPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isMoving, setIsMoving] = useState<boolean>(true);

  const totalSteps = 7;

  const methods = useForm<NewUser>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
  });

  const handleNext = () => {
    if (currentStep === 4 && !isMoving) return setCurrentStep(6);
    setCurrentStep(prev => prev + 1);
  };

  const handlePrev = () => {
    setCurrentStep(prev => prev - 1);
  };

  const onSubmit = (data: NewUser) => {
    console.log(data);
  };

  return (
    <FormProvider {...methods}>
      <div className="mx-auto flex min-h-screen max-w-5xl flex-col items-center justify-between px-5">
        <header className="flex w-full py-5">
          <HomeLogo />
        </header>
        <form className="w-full max-w-lg p-6" onSubmit={methods.handleSubmit(onSubmit)}>
          <NameStep onNext={handleNext} isActive={currentStep === 0} />
          <EmailStep onNext={handleNext} isActive={currentStep === 1} />
          <MovingSoonStep
            onNext={handleNext}
            isActive={currentStep === 2}
            setIsMoving={(val: boolean) => setIsMoving(val)}
          />
          <MoveStatusStep onNext={handleNext} isActive={currentStep === 3} />
          <CurrentAddressStep onNext={handleNext} isActive={currentStep === 4} />
          {!!isMoving && <NewAddressStep onNext={handleNext} isActive={currentStep === 5} />}
          <StepSeven onNext={handleNext} isActive={currentStep === 6} />
        </form>
        <footer className="flex w-full flex-col items-start gap-8 py-5">
          {currentStep > 0 && (
            <button
              onClick={handlePrev}
              className="group flex items-center gap-3 rounded-full border border-primary bg-transparent px-5 py-2 transition-all hover:bg-primary hover:text-white"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 16 16"
                className="fill-primary group-hover:fill-white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16L9.41 14.59L3.83 9L16 9L16 7L3.83 7L9.41 1.41L8 -3.95616e-07L1.23639e-06 8L8 16Z"
                  fill="current"
                />
                <path
                  d="M8 16L9.41 14.59L3.83 9L16 9L16 7L3.83 7L9.41 1.41L8 -3.95616e-07L1.23639e-06 8L8 16Z"
                  fill="current"
                />
              </svg>
              <span>Go back</span>
            </button>
          )}
          <div className="relative h-1 w-full bg-white">
            <div
              className={twMerge('h-1 bg-home')}
              style={{ width: `${((currentStep + 1) / totalSteps) * 100}%` }}
            />
          </div>
          <nav className="flex w-full items-center gap-8 text-sm font-medium">
            <span>&copy; Copyright 2024 Home. All rights reserved.</span>
            <a href={Routes.TERMS} className="hover:underline">
              Terms &amp; Conditions
            </a>
            <a href={Routes.PRIVACY} className="hover:underline">
              Privacy Policy
            </a>
          </nav>
        </footer>
      </div>
    </FormProvider>
  );
};

import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { Button } from '@components/Button';
import { SelectField } from '@components/Select/SelectField';
import { Spinner } from '@components/Spinner';
import { TextField } from '@components/TextField';

import { GET_ADDRESS_API_KEY } from '@constants/index';

import { useFetchAddressSuggestions } from '@hooks/useFetchAddressSuggestions';
import { useToast } from '@hooks/useToast';

import { NewUser } from '..';
import { Step } from '../step';


interface StepProps {
  onNext: () => void;
  isActive: boolean;
}

export const CurrentAddressStep = ({ onNext, isActive }: StepProps) => {
  const [showManualAddress, setShowManualAddress] = useState(false);
  const { setSearchTerm, isFetching, suggestions, error } = useFetchAddressSuggestions();
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<NewUser>();
  const { dispatchErrorToast } = useToast();

  if (!isActive) {
    return null;
  }

  const currentAddress = watch('currentAddress');

  const isButtonDisabled =
    !currentAddress ||
    !currentAddress.addressLine1 ||
    !currentAddress.city ||
    !currentAddress.postcode;

  const getAddressDetails = async (id: string) => {
    try {
      const response: Response = await fetch(
        `https://api.getAddress.io/get/${id}?api-key=${GET_ADDRESS_API_KEY}`,
        {
          method: 'GET',
        }
      );

      const data = await response.json();

      if (data) {
        setValue('currentAddress.addressLine1', data.line_1);
        setValue('currentAddress.addressLine2', data.line_2);
        setValue('currentAddress.city', data.town_or_city);
        setValue('currentAddress.postcode', data.postcode);
      }
    } catch (error) {
      dispatchErrorToast('Something went wrong fetching address');
    }
  };

  return (
    <Step title="What is your current address?">
      <div className="flex w-full flex-col items-center gap-5">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex flex-1 items-center gap-4">
            <TextField
              id="fromSearch"
              label="Search by postcode"
              placeholder="Lookup postcode"
              styles="col-span-1"
              onChange={e => setSearchTerm(e.target.value)}
              error={error}
            />

            <span className={twMerge('mt-4', !!isFetching ? 'opacity-100' : 'opacity-0')}>
              <Spinner colour="black" />
            </span>
          </div>

          <button
            type="button"
            className="col-span-1 mt-5 text-left underline hover:no-underline"
            onClick={() => setShowManualAddress(!showManualAddress)}
          >
            {!!showManualAddress ? 'Hide address' : 'Enter manually'}
          </button>
        </div>

        <div className="col-span-2 w-full">
          <SelectField
            id="currentSuggestedAddress"
            label="Address"
            options={[{ key: 'Select address', value: '', disabled: true }, ...suggestions]}
            placeholder="Select address"
            styles="w-full"
            disabled={suggestions.length === 0}
            onSelect={val => getAddressDetails(val)}
          />
        </div>

        <div
          className={twMerge(
            'w-full overflow-hidden transition-all duration-500 ease-in-out',
            showManualAddress ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
          )}
        >
          {showManualAddress && (
            <div className="col-span-4 mt-5 flex w-full flex-col gap-y-5">
              <TextField
                required
                id="addressLine1"
                label="Address Line 1"
                placeholder="Address line 1"
                error={errors.currentAddress?.addressLine1}
                {...register('currentAddress.addressLine1')}
              />
              <TextField
                id="addressLine2"
                label="Address Line 2"
                placeholder="Address line 2"
                error={errors.currentAddress?.addressLine2}
                {...register('currentAddress.addressLine2')}
              />
              <TextField
                required
                id="currentAddress.city"
                label="City"
                placeholder="City"
                error={errors.currentAddress?.city}
                {...register('currentAddress.city')}
              />
              <TextField
                required
                id="currentAddress.postcode"
                label="Postcode"
                placeholder="Postcode"
                styles="w-1/2"
                error={errors.currentAddress?.postcode}
                {...register('currentAddress.postcode')}
              />
            </div>
          )}
        </div>
      </div>
      <Button onClick={onNext} disabled={isButtonDisabled}>
        Next
      </Button>
    </Step>
  );
};

import { InsuranceReminderSignpost } from '@components/Signpost';

import { BlogLinks } from '@constants/index';

import { MoveStatus } from '@features/profile/profile.types';

import { ContentBlock } from '../../components/ContentBlock';

type Props = {
  moveStatus: string;
};

export const Exchange = ({ moveStatus }: Props) => {
  const buyerSeller = moveStatus === MoveStatus.BUYER ? 'seller' : 'buyer';
  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <ContentBlock
            title="What to expect"
            content={`This is where it becomes official. Your solicitor and the ${buyerSeller}'s solicitor will swap signed copies of the contract. This makes it a legally-binding contract and you really start to celebrate.`}
            link={BlogLinks.EXCHANGE}
            buttonText="Read our guide to exchanging contracts"
            showBoxOnDesktop
          />
        </div>
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <InsuranceReminderSignpost />
        </div>
      </div>
    </>
  );
};

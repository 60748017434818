import { LinkButton } from '@components/Button';
import { ConciergeReminderSignpost } from '@components/Signpost';

import { BlogLinks, ProductLinks } from '@constants/index';

import { MoveStatus } from '@features/profile/profile.types';

import EverythingToMakeItHome from '@images/everything_to_make_it_home.webp';

import { ContentBlock } from '../../components/ContentBlock';
type Props = {
  moveStatus: string;
  address: string;
};

export const Complete = ({ moveStatus, address }: Props) => {
  const firstParagraph =
    moveStatus === MoveStatus.BUYER
      ? `The big day! Your moment to finally relax as the conveyancing process has finished and
              you can move into ${address}!`
      : `The big day! Your moment to finally relax as the conveyancing process has finished and
              you move out of ${address}!`;

  const secondParagraph =
    moveStatus === MoveStatus.BUYER
      ? `Ownership of the property is transferred along with funds and you can pick up the
              keys. After that your conveyancer will then register the change of ownership with the Land
              Registry.`
      : `Ownership of the property is transferred along with funds and you can hand over the
              keys. After that your conveyancer will then register the change of ownership with the Land
              Registry.`;

  return (
    <>
      <div className="flex flex-col items-center gap-10 px-7 pb-12 md:flex-row md:items-start md:gap-14 md:px-10">
        <div id="column" className="flex flex-col gap-10 md:basis-1/2 md:gap-14">
          <ContentBlock
            title="What to expect"
            link={BlogLinks.COMPLETE}
            buttonText="Read our guide to completing"
            showBoxOnDesktop
          >
            {moveStatus === MoveStatus.TENANCY ? (
              <p className="text-lg font-light">
                The big day! Your moment to finally relax as you get the keys to {address} and start
                making it Home!
              </p>
            ) : (
              <>
                <p className="text-lg font-light">{firstParagraph}</p>
                <p className="text-lg font-light">{secondParagraph}</p>
              </>
            )}
          </ContentBlock>
        </div>
        <div id="column" className="flex flex-col gap-10 md:mt-5 md:basis-1/2 md:gap-14">
          <hr className="w-full border-stone-200 md:hidden" />
          <ConciergeReminderSignpost />
        </div>
      </div>
      <hr className="w-full border-stone-200" />
      <div className="flex w-full flex-col items-center justify-center gap-5 py-12 md:flex-row md:gap-10">
        <img
          src={EverythingToMakeItHome}
          alt="Everything to make it home"
          className="w-full max-w-sm"
        />
        <div className="flex flex-col gap-5">
          <h4 className="max-w-sm text-center text-3xl font-medium">
            Everything you need to make it Home
          </h4>
          <p className="max-w-sm text-center">
            Make managing your home simple and rewarding, from utilities and services to cleaners
            and tradespeople.
          </p>
          <LinkButton href={ProductLinks.SERVICES} target="_blank">
            View all home services
          </LinkButton>
        </div>
      </div>
    </>
  );
};

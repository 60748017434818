import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { Input } from '@components/Input';
import { SelectField } from '@components/Select/SelectField.tsx';

import { selectProfile } from '@features/profile/profile.ts';

import { ReactComponent as Home } from '@images/home-v1.svg';
import { Conveyancing, Envelope, Phone, Tick } from '@images/icons';
import { ReactComponent as PoweredByMovemnt } from '@images/powered-by-movemnt.svg';
import { ReactComponent as SRA } from '@images/solicitors-regulation-authority.svg';


import {
  Address,
  HomeQuote,
  postcodeRegExp,
  showLogo,
  whyInstructDetailed,
} from '@pages/services/Conveyancing/index.tsx';
import { toggleSwitch } from '@pages/services/Conveyancing/toggleSwitch.tsx';

import customAxios from '@utils/customAxios.tsx';

export const Instruction = React.memo((
    { selectedQuote, sessionId, quoteIds, correspondenceAddress, setIsSubmitting }: {
      selectedQuote: HomeQuote,
      sessionId: string,
      quoteIds: string[],
      correspondenceAddress: Address | undefined,
      setIsSubmitting: (bool: boolean) => any
    },
  ) => {

    const profile = useSelector(selectProfile);
    const firstName = profile?.value?.customer?.name?.firstName ?? '';
    const lastName = profile?.value?.customer?.name?.lastName ?? '';
    const email = profile?.value?.customer?.email ?? '';
    const phone = profile?.value?.customer?.phone ?? '';

    type Instruction = {
      instructionDetails: {
        firstName: string
        lastName: string
        email: string,
        phone: string,
        correspondenceAddress: Address,
        title: string,
        serviceTermsConsent: boolean,
      } | undefined,
    }

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<Instruction>({
      mode: 'onBlur',
      resolver: async (values, context, options) => {
        const createResolver = zodResolver(z.object({
          instructionDetails: z.object({
            firstName: z.string(),
            lastName: z.string(),
            email: z.string().email('Invalid email').min(1, 'Email is required'),
            phone: z.string().min(10, 'Phone is required'),
            correspondenceAddress: z.object({
              line1: z.string().min(1, 'Address line 1 required'),
              line2: z.string().optional(),
              city: z.string().min(1, 'City is required'),
              country: z.string().optional(),
              postcode: z
                .string()
                .min(1, 'Moving from postcode is required')
                .regex(postcodeRegExp, 'Invalid postcode'),
            }),
            title: z.enum(['Mr', 'Mrs', 'Ms']),
            serviceTermsConsent: z.boolean(),
          }),
        }));
        const promise = createResolver(values, context, options);
        // promise.then(res => console.log(res));
        return promise;
      },
      defaultValues: {
        instructionDetails: {
          firstName,
          lastName,
          email,
          phone,
          correspondenceAddress,
          title: 'Mr',
          serviceTermsConsent: false,
        },
      },
    });

    const [showInstructionSuccess, setShowInstructionSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    async function onSubmit(data: Instruction) {
      if (data.instructionDetails?.serviceTermsConsent) {
        setIsSubmitting(true);
        try {
          const response = await customAxios('/api/home/conveyancing/instruct', {
            method: 'POST',
            data: {
              sessionId,
              quoteIds: quoteIds,
              address: data.instructionDetails.correspondenceAddress,
              person: {
                title: data.instructionDetails.title,
                firstName: data.instructionDetails.firstName,
                lastName: data.instructionDetails.lastName,
                email: data.instructionDetails.email,
                phone: data.instructionDetails.phone,
                consent: data.instructionDetails.serviceTermsConsent,
              },
            },
          });
          const status = response.status;
          setIsSubmitting(false);
          if (status === 200) {
            setShowInstructionSuccess(true);
          }
        } catch (e) {
          Sentry.captureException(e);
          setIsSubmitting(false);
          setShowError(true);
        }
      } else {
        // console.error('No consent to do this');
      }
    }

    return <div>
      <div className={'flex'}>
        <div
          className={'bg-white lg:flex lg:flex-col lg:items-center lg:rounded-2xl lg:px-12 lg:py-8 lg:w-3/5 lg:max-w-[1256px]'}>
          <div className={'flex flex-col gap-y-4 lg:w-3/4'}>
            <div className={'text-xl font-medium text-center'}>Your costs</div>
            <div className={'text-lg font-medium'}>Legal transaction fees</div>
            {selectedQuote.sale ? <div>
              <div className={'font-medium underline mb-2'}>Sale</div>
              {
                selectedQuote.sale.fees.map(f => (
                  <div className={'flex justify-between'}>
                    <div className={'self-start'}>{f.name}:</div>
                    <div className={'self-end'}>£{f.netValue}</div>
                  </div>
                ))
              }
            </div> : null}
            {selectedQuote.purchase ? <div>
              <div className={'font-medium underline mb-2'}>Purchase</div>
              {
                selectedQuote.purchase.fees.map(f => (
                  <div className={'flex justify-between'}>
                    <div className={'self-start'}>{f.name}:</div>
                    <div className={'self-end'}>£{f.netValue}</div>
                  </div>
                ))
              }
            </div> : null
            }
            <div className={'text-lg font-medium mt-4'}>Disbursements</div>
            {selectedQuote.sale ? <div>
              <div className={'font-medium underline mb-2'}>Sale</div>
              {
                selectedQuote.sale.disbursements.map(f => (
                  <div className={'flex justify-between'}>
                    <div className={'self-start'}>{f.name}:</div>
                    <div className={'self-end'}>£{f.netValue}</div>
                  </div>
                ))
              }
            </div> : null}
            {selectedQuote.purchase ? <div>
              <div className={'font-medium underline mb-2'}>Purchase</div>
              {
                selectedQuote.purchase.disbursements.map(f => (
                  <div className={'flex justify-between'}>
                    <div className={'self-start'}>{f.name}:</div>
                    <div className={'self-end'}>£{f.netValue}</div>
                  </div>
                ))
              }
            </div> : null
            }
            <div className={'flex justify-between mt-4'}>
              <div className={'self-start  text-lg font-medium'}>VAT:</div>
              <div className={'self-end'}>£{selectedQuote.totalVat.toFixed(2)}</div>
            </div>
            <div className={'w-[95%] my-4 m-auto border-[0.5px] border-black'}></div>
            <div className={'flex justify-between'}>
              <div className={'self-start  text-xl font-medium'}>Total Conveyancing fee:</div>
              <div
                className={'self-end'}>£{selectedQuote.totalCost.toFixed(2)}</div>
            </div>
            <div className={'flex items-center'}>
              <SRA width={'60px'} />
              <div className={'ml-4'}>Regulated by the Solicitors Regulation Authority (5926234)&nbsp;
                <a
                  className={'underline'}
                  referrerPolicy={'no-referrer'}
                  target={'_blank'}
                  href={'https://www.sra.org.uk/'} rel="noreferrer"
                >Learn more</a></div>
            </div>
          </div>
          <div className={'flex flex-col lg:w-3/4'}>
            <div className={'w-full my-16 m-auto border-[0.5px] border-gray-400'}></div>
            <div className={'flex flex-col'}>
              <div className={'text-xl text-center font-medium'}>Start instructing</div>
              <div className={'text-lg text-center mt-4'}>
                Tick one more thing off your list and start moving forwards with {selectedQuote.name} today.
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col items-center w-full gap-y-4 mt-4">
                  <SelectField
                    styles="w-full"
                    options={[
                      { key: 'Mr', value: 'Mr' },
                      { key: 'Mrs', value: 'Mrs' },
                      { key: 'Ms', value: 'Ms' },
                    ]}
                    id="instructionDetails.title"
                    label="Title"
                    required={true}
                    key={'instructionDetails.title'}
                    error={errors.instructionDetails?.title}
                    {...register('instructionDetails.title')}
                  />
                  <Input
                    id="instructionDetails.firstName"
                    label="First name"
                    placeholder="first name"
                    required={true}
                    error={errors.instructionDetails?.firstName}
                    {...register('instructionDetails.firstName')}
                  />
                  <Input
                    id="instructionDetails.lastName"
                    label="Last name"
                    placeholder="last name"
                    required={true}
                    error={errors.instructionDetails?.lastName}
                    {...register('instructionDetails.lastName')}
                  />
                  <Input
                    id="instructionDetails.email"
                    label="Email"
                    placeholder="email"
                    required={true}
                    error={errors.instructionDetails?.email}
                    {...register('instructionDetails.email')}
                  />
                  <Input
                    id="instructionDetails.phone"
                    label="Phone"
                    placeholder="+44..."
                    required={true}
                    error={errors.instructionDetails?.phone}
                    {...register('instructionDetails.phone')}
                  />
                  <div className={"self-start"}>Correspondence address</div>
                  <div className={"self-start text-sm"}>This is where we will send any accompanying materials.</div>
                  <Input
                    id="instructionDetails.correspondenceAddress.line1"
                    label="Address Line 1"
                    placeholder="Address line 1"
                    required={true}
                    error={errors.instructionDetails?.correspondenceAddress?.line1}
                    {...register('instructionDetails.correspondenceAddress.line1')}
                  />
                  <Input
                    id="saleDetails.address.line2"
                    label="Address Line 2"
                    placeholder="Address line 2"
                    error={errors.instructionDetails?.correspondenceAddress?.line2}
                    {...register('instructionDetails.correspondenceAddress.line2')}
                  />
                  <Input
                    id="saleDetails.address.city"
                    label="City"
                    placeholder="City"
                    required={true}
                    error={errors.instructionDetails?.correspondenceAddress?.city}
                    {...register('instructionDetails.correspondenceAddress.city')}
                  />
                  <Input
                    id="correspondenceAddress.correspondenceAddress.postcode"
                    label="Postcode"
                    placeholder="Postcode"
                    required={true}
                    error={errors.instructionDetails?.correspondenceAddress?.postcode}
                    {...register('instructionDetails.correspondenceAddress.postcode')}
                  />
                  <SelectField
                    options={[
                      { key: 'England', value: 'England' },
                      { key: 'Wales', value: 'Wales' },
                      { key: 'Ireland', value: 'Ireland' },
                      { key: 'Scotland', value: 'Scotland' },
                    ]}
                    styles={'w-full'}
                    id="saleDetails.address.country"
                    label="Country"
                    required={true}
                    error={errors.instructionDetails?.correspondenceAddress?.country}
                    {...register('instructionDetails.correspondenceAddress.country')}
                  />
                  <div className={'self-start'}>
                    {toggleSwitch(
                      { ...register('instructionDetails.serviceTermsConsent') },
                      'instructionDetails.serviceTermsConsent',
                      <div>I consent to the&nbsp;
                        <a
                          className={'text-sm underline self-start'}
                          rel={'noreferrer'}
                          target={'_blank'}
                          href={'https://www.zoopla.co.uk/zoopla-transaction-network-terms-and-conditions/'}>
                          service terms
                        </a>
                      </div>,
                      true)
                    }
                  </div>
                </div>
                <button
                  className={'mt-4 w-fit self-center rounded-xl bg-primary px-8 py-3 text-lg text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer'}
                  type={'submit'}
                >Let's go
                </button>
                {showError ? <div className={'w-full mt-2'}>
                  <div>Something went wrong, please try again or speak to us through our live chat now</div>
                </div> : null}
              </form>
            </div>
            <div className={'w-full my-16 m-auto border-[0.5px] border-gray-400'}></div>
          </div>
        </div>
        <div className={'flex flex-col gap-y-4 w-2/5 p-4 lg:py-0 hidden lg:flex'}>
          <PoweredByMovemnt className={'fill-amber-50 mb-4'} />
          {contactDetails()}
          {whyInstructDetailed()}
        </div>
      </div>

      <div className={'flex flex-col gap-y-4 w-full lg:mt-10 lg:hidden'}>
        <div className={'text-lg lg:text-xl lg:font-medium text-center'}>Why instruct:</div>
        <div className={'flex lg:justify-end'}>
          <div className={'hidden lg:block lg:w-[100px]'}>
            <Conveyancing />
          </div>
          <div className={'flex flex-col ml-5 lg:ml-20'}>
            <div className={'flex items-center'}>
              <Tick width={'25px'} />
              <div className={'w-[75%] ml-4'}>Track every step right here with real-time updates at every stage
              </div>
            </div>
            <div className={'flex items-center'}>
              <Tick width={'25px'} />
              <div className={'w-[75%] ml-4'}>No move, no fee</div>
            </div>
            <div className={'flex items-center'}>
              <Tick width={'25px'} />
              <div className={'w-[75%] ml-4'}>100,000+ conveyances a year</div>
            </div>
          </div>
        </div>
      </div>

      <div className={'lg:hidden'}>
        {contactDetails()}
      </div>


      {showInstructionSuccess ? <div className={'fixed top-0 left-0 w-full h-screen bg-home z-[99]'}>
        <div className="flex flex-col items-center w-full gap-y-7 my-20">
          <section className={'w-full md:w-[500px] text-white'}>
            {selectedQuote ?
              <div className={'bg-home flex flex-col items-center gap-y-8'}>
                <div className={'flex gap-x-4 items-center'}>
                  <Tick className={'fill-amber-50 w-10'} />
                  <div className={'text-xl font-medium'}>Thank you {firstName}!</div>
                </div>
                <div className={'text-center'}>
                  Congrats, that’s one more thing ticked off your to do list!
                </div>
                {showLogo(selectedQuote)}
                <div className={'text-lg font-medium'}>You’re now connected to a conveyancer.</div>
                <div className={'text-md font-medium text-center'}>What happens next</div>
                <div className={'text-center'}>
                  Your conveyancer will be in touch shortly to start progressing your move.
                </div>
                <button
                  type="button"
                  className="rounded-xl w-[80%] bg-secondary text-black px-8 py-3 text-md font-medium transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  View Conveyancing
                </button>
                <div></div>
              </div> : <></>}
          </section>
        </div>
      </div> : null}
    </div>;

    function contactDetails() {
      return <div
        className={'flex flex-col items-center lg:items-start mt-8 lg:mt-0 gap-y-4 lg:bg-white lg:rounded-2xl lg:p-4'}>
        {(selectedQuote.phone || selectedQuote.email) ?
          <div className={'text-lg lg:font-medium'}>Get in touch</div> : null}
        {selectedQuote.phone ? <div className={'flex w-full justify-center lg:justify-start items-center'}>
            <Phone width={'30px'} className={'self-start'} />
            <div className={'ml-4'}>{selectedQuote.phone}</div>
          </div>
          : null
        }
        {selectedQuote.email ? <div className={'flex w-full justify-center lg:justify-start items-center'}>
            <Envelope width={'30px'} className={'self-start'} />
            <div className={'ml-4'}>{selectedQuote.email}</div>
          </div>
          : null
        }
        <div className={'flex w-full justify-center lg:justify-start  items-center'}>
          <Home width={'30px'} className={'self-start'} />
          <a className={'ml-4 underline'}
             href={selectedQuote?.website}
             target="_blank"
             rel="noreferrer">{selectedQuote?.website?.slice(0, 30) + (selectedQuote?.website?.length > 30 ? '...' : '')}</a>
        </div>
      </div>;
    }

  },
);

import { useFormContext } from 'react-hook-form';

import { Button } from '@components/Button';
import { TextField } from '@components/TextField';

import { NewUser } from '..';
import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
}

export const EmailStep = ({ onNext, isActive }: StepProps) => {
  const { register, watch } = useFormContext<NewUser>();

  const email = watch('email');

  if (!isActive) {
    return null;
  }

  const isButtonDisabled = !email;

  return (
    <Step title="What's your email?">
      <div className="flex w-full flex-col gap-8">
        <TextField
          {...register('email', { required: 'Email is required' })}
          type="text"
          id="email"
          label="Email"
        />
        <div className="flex w-full justify-center">
          <Button onClick={onNext} disabled={isButtonDisabled}>
            Next
          </Button>
        </div>
      </div>
    </Step>
  );
};

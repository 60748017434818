import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
}

export const NewAddressStep = ({ onNext, isActive }: StepProps) => {
  if (!isActive) {
    return null;
  }

  const onSubmit = () => {
    onNext();
  };

  return <Step title="Where are you moving to?">Six</Step>;
};
